<template>
  <div class="bg-white p-10 flex align-items-center search-table-title">
    <div class="fs-15 fw-600 label">{{ label }}</div>
    <div class="flex-1 text-right">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivitySearchTableTitle',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
};
</script>
