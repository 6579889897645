<template>
    <div
        ref="formBox"
        class="activity-search-form"
        :class="{ toggle: !toggleArrow }"
    >
        <slot />
        <div v-if="flod" class="toggle-form-btn">
            <el-button v-if="arrow" type="text" @click="changeArrow">
                {{ !toggleArrow ? '展开' : '收起' }}
                <span
                    class="el-icon-arrow-down"
                    :class="{ rotate: toggleArrow }"
                />
            </el-button>
        </div>
        <div class="form-btns text-center">
            <el-button
                v-if="addButton"
                :size="size"
                type="primary"
                @click="$emit('add')"
                >{{ addText }}</el-button
            >
            <el-button :size="size" type="primary" @click="$emit('search')"
                >查询</el-button
            >
            <el-button v-if="resetButton" :size="size" @click="$emit('reset')"
                >重置</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchForm',
    props: {
        params: {
            type: Object,
            default: null,
        },
        flod: Boolean,
        resetButton: {
            type: Boolean,
            default: true,
        },
        addButton: {
            type: Boolean,
            default: false,
        },
        addText: {
            type: String,
            default: '新增',
        },
        itemWidth: {
            type: Number,
            default: 360,
        },
        size: {
            type: String,
            default: 'small',
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            arrow: false,
            form: null,
            toggleArrow: false,
            formChildren: 0,
            averageValue: 0,
            formBoxHeight: 0,
        };
    },
    computed: {
        needArrow() {
            return this.formChildren.length > this.averageValue;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.flodItems();
            this.formBoxHeight = this.$refs.formBox.clientHeight;
        });
        window.addEventListener('resize', this.flodItems);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.flodItems);
    },
    methods: {
        flodItems() {
            const reg = /^vue-component-\d+-ElForm$/;
            this.form = this.$slots.default.find((v) => reg.test(v.tag));
            if (!this.form) {
                window.console.error(
                    '[generalFramework warn]: The content of the default slot of the <csmb-search-form> must use the <el-form><el-form-item /><el-form>!'
                );
                return;
            }
            this.formChildren = this.form.child.$children;
            this.averageValue = parseInt(
                this.form.elm.getBoundingClientRect().width / this.itemWidth,
                10
            );
            this.formChildren.forEach((child) => {
                child.$el.style.width = `${100 / this.averageValue}%`;
            });
            this.arrow = this.needArrow;
            this.toggleArrow = this.needArrow;
        },
        changeArrow() {
            this.toggleArrow = !this.toggleArrow;
            this.$emit('toggleHeight');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../style/search-form.scss';
</style>
