<template>
    <div ref="tableBox" v-resize="changeTableHeight" class="search-table">
        <!--树形table-->
        <template v-if="treeTable">
            <el-table
                ref="table"
                v-loading="loading"
                :height="height"
                :data="data"
                :row-key="rowKey"
                border
                stripe
                :default-expand-all="defaultExpandAll"
                :tree-props="treeProps"
            >
                <slot />
            </el-table>
        </template>
        <!--常规table-->
        <template v-else>
            <el-table
                ref="table"
                size="small"
                v-loading="loading"
                :data="data"
                stripe
                border
                v-on="$listeners"
            >
                <slot />
            </el-table>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SearchTable',
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
        treeTable: {
            type: Boolean,
            default: false,
        },
        defaultExpandAll: {
            type: Boolean,
            default: true,
        },
        rowKey: {
            type: String,
            default: 'id',
        },
        treeProps: {
            type: Object,
            default() {
                return {
                    children: 'children',
                    hasChildren: 'hasChildren',
                };
            },
        },
    },
    data() {
        return {
            resizeNumber: 0,
            height: 200,
        };
    },

    methods: {
        changeTableHeight() {
            this.$parent.$nextTick(() => {
                if (this.$refs.tableBox) {
                    this.height =
                        this.$refs.tableBox &&
                        this.$refs.tableBox.clientHeight - 10;
                    this.$refs.table.doLayout();
                }
            });
        },
    },
};
</script>
<style>
.search-table {
    background: #fff;
    flex: 1;
    overflow: hidden;
}
</style>
