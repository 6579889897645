<template>
  <div class="activity-search-pagination">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SearchPagination'
};
</script>
