import {
  SearchForm,
  SearchPagination,
  SearchTable,
  SearchTableTitle,
} from './tableBaseComponents';

var TableBase = {
  components: { SearchForm, SearchPagination, SearchTable, SearchTableTitle },
  data() {
    return {
      inputSize: 'small',
      params: {},
      tableData: [],
      tableDataLoading: false,
      paginationParmas: {
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      selected: [],
    };
  },
  filters: {
    fitlerDictionary(val, data) {
      const item = data.find((v) => v.value === val);
      return item ? item.label : val;
    },
    fitlerTime(data) {
      const start = data.startTime;
      const end = data.endTime;
      if (start && end) {
        return `${start} ~ ${end}`;
      }
      if (start && !end) {
        return `${start} ~ 长期`;
      }
      if (!start && end) {
        return `${data.createdTime} ~ ${end}`;
      }
      if (!start && !end) {
        return `长期`;
      }
      return '';
    },
  },
  computed: {
    tableTitle() {
      return this.$route?.meta?.title || '';
    },
  },
  methods: {
    /**
     * pageSize改变
     */
    sizeChange(value) {
      this.paginationParmas.pageSize = value;
      this.getData();
    },
    /**
     * 重置搜索表达
     */
    reset() {
      this.paginationParmas.pageIndex = 1;
      this.$refs.form.resetFields();
      this.changeDate && this.changeDate();
      this.getData && this.getData();
    },
    /**
     * 改变table高度
     */
    changeTableHeight() {
      this.$refs.table.changeTableHeight();
    },
    /**
     * 当前页改变
     */
    currentChange(value) {
      this.paginationParmas.pageIndex = value;
      this.getData();
    },
    clearSelected() {
      this.$refs.table.$refs.table.clearSelection();
      this.selected = [];
    },
    selectChange(v) {
      const ids = v.map((item) => item.id);
      this.selected = [
        ...ids,
        ...this.selected.filter(
          (id) => !this.tableData.some((r) => r.id === id)
        ),
      ];
    },
  },
};
export default TableBase;
