<template>
  <div class="compoWrap">
    <el-button @click="outPut">导出</el-button>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="signsNames" label="体征类型"></el-table-column>
      <el-table-column prop="channelName" label="数据来源"> </el-table-column>
      <el-table-column prop="recordTime" label="记录时间"> </el-table-column>
      <el-table-column prop="val" label="最新数值">
        <template slot-scope="scope">
          {{ scope.row.val }}{{ scope.row.unit }}
        </template>
      </el-table-column>
      <el-table-column prop="standardVal" label="参考范围"> </el-table-column>
    </el-table>
    <el-dialog title="导出" :visible.sync="dialogVisible" width="40%">
      <div>
        <el-checkbox-group v-model="signCodeList">
          <el-checkbox
            v-for="(item, index) in outPutData"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
        <br />
        <div>导出范围：</div>
        <br />
        <div>
          <el-radio label="num" v-model="ranger"
            >近<el-input
              type="number"
              v-model="topN"
              size="mini"
              class="input"
            ></el-input
            >条</el-radio
          >
          <el-radio label="all" v-model="ranger"> 全部 </el-radio>
        </div>
        <br />
        <div class="bodyDataflex">
          时间&nbsp;&nbsp;&nbsp;
          <el-date-picker
            v-model="startRecordTime"
            type="date"
            placeholder="选择日期"
            size="mini"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
            > </el-date-picker
          >-
          <el-date-picker
            v-model="endRecordTime"
            type="date"
            placeholder="选择日期"
            size="mini"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="signDataDown">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TableBase from '@/mixins/TableBase';
import { queryLatestSignData, exportSignData } from '@/api/healthRecord';
// import { exportExcel } from '@/utils/downbase';

import { downloadUrl } from '@/utils/index';
function destructureWithDefault(obj, key, defaultValue) {
  const value = obj[key];
  return value !== undefined && value !== null ? value : defaultValue;
}
export default {
  mixins: [TableBase],
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      params: {
        userId: this.userId,
      },
      signCodeList: [],
      dialogVisible: false,
      outPutData: [
        {
          value: 'HEIGHT',
          label: '身高',
          select: false,
        },
        {
          value: 'WEIGHT',
          label: '体重',
          select: false,
        },
        {
          value: 'BMI',
          label: 'BMI',
          select: false,
        },
        {
          value: 'WAIST',
          label: '腰围',
          select: false,
        },
        {
          value: 'BODY_FAT_RATE',
          label: '体脂',
          select: false,
        },
        {
          value: 'STEP',
          label: '步数',
          select: false,
        },
        {
          value: 'SLEEP',
          label: '睡眠',
          select: false,
        },
        {
          value: 'BLOOD_SUGAR',
          label: '血糖',
          select: false,
        },
        {
          value: 'BLOOD_PRESSURE',
          label: '血压',
          select: false,
        },
        {
          value: 'HEART_RATE',
          label: '心率',
          select: false,
        },
        {
          value: 'TEMPERATURE',
          label: '体温',
          select: false,
        },
        {
          value: 'URIC_ACID',
          label: '尿酸',
          select: false,
        },
        {
          value: 'CHOLESTEROL',
          label: '胆固醇',
          select: false,
        },
        {
          value: 'PULSE',
          label: '脉搏',
          select: false,
        },
        {
          value: 'HIPLINE	',
          label: '臀围',
          select: false,
        },
        {
          value: 'WHR',
          label: '腰臀比',
          select: false,
        },
        {
          value: 'BLOOD_OXYGEN',
          label: '血氧',
          select: false,
        },
        {
          value: 'WATER_RATE',
          label: '体内水分含量',
          select: false,
        },
        {
          value: 'MUSCLE',
          label: '肌肉质量',
          select: false,
        },
        {
          value: 'BONE',
          label: '骨骼质量',
          select: false,
        },
        {
          value: 'BASIC_METABOLISM',
          label: '基础代谢',
          select: false,
        },
      ],
      topN: '',
      ranger: '',
      startRecordTime: '',
      endRecordTime: '',
    };
  },
  created() {
    this.signCodeList = [];
    this.getData();
  },
  methods: {
    async signDataDown() {
      const params = {
        userId: this.userId,
        signCodeList: this.signCodeList,
        topN: this.ranger === 'num' ? this.topN : '-1',
        startRecordTime: this.startRecordTime,
        endRecordTime: this.endRecordTime,
      };
      // this.downHandle(params);

      const res = await exportSignData(params);

      const fileName =
        res.headers['content-disposition']?.split(
          'attachment;filename='
        )?.[1] || '导出文件.xls';
      downloadUrl(res.data, decodeURIComponent(fileName));
    },
    // 下载
    downHandle(params) {
      // const url =
      //   process.env.VUE_APP_BASE_CONTENT_PATH +
      //   process.env.VUE_APP_MEMBER_PATH +
      //   '/healthRecords/signDataDown';
      // exportExcel('导出文件.xls', url, params, 'post');
    },
    outPut() {
      this.dialogVisible = true;
    },
    getData() {
      const _data = Object.assign({}, this.params, this.paginationParmas);
      this.tableData = [];
      this.tableDataLoading = true;
      queryLatestSignData(_data)
        .then((res) => {
          this.tableData = res.data;
          console.log(res);
        })
        .finally(() => {
          this.tableDataLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.compoWrap {
  padding: 20px;
  width: 100%;
  height: 90vh;
  background-color: #fff;
  overflow-y: scroll;
}
.input {
  display: inline-block;
  width: 100px;
  margin: 0 10px;
}
.bodyDataflex {
  display: flex;
}
</style>
